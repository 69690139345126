import { useContext } from "react";
import { SettingsContext, ShopContext } from "../App";
import AddToCartButton from "./AddToCartButton";
import currencyFormat from "./functions/functions";
import Badges from "./Badges";
import Tags from "./Tags";
import Groups from "./Groups";

function ProductsList({ products }) {
	const { settings, setProductSheet } = useContext(SettingsContext);
	const { shop } = useContext(ShopContext);

	if (!products) return null;

	return (
		<>
			{settings.selfordering.list_orizzontal == 1 ? (
				<div className="products list-horizontal">
					{products.map((product) => (
						<div key={product.id} className="product-horizontal">
							<div className="img" onClick={() => setProductSheet(product)}>
								{product.img ? (
									<img src={product.img} />
								) : (
									<div className="img-placeholder" />
								)}
								<Badges product={product} />
							</div>
							<div className="info">
								<div onClick={() => setProductSheet(product)}>
									<b className="title">{product.name}</b>
									{Object.hasOwn(product, "stock") && product.stock !== false && (
										<div className="stock small">Restanti: {product.stock}</div>
									)}
									<Groups product={product} />
									<Tags product={product} />
									<div
										className="small description"
										dangerouslySetInnerHTML={{ __html: product.description }}
									/>
								</div>
								<div className="price-line">
									<div
										className="price"
										style={{ color: settings.selfordering.color1 }}
									>
										<b>
											{product.discounted ? (
												<>
													<s>
														{currencyFormat(
															product.price,
															false,
															shop.currency
														)}
													</s>
													{" - " +
														currencyFormat(
															product.discount.price,
															false,
															shop.currency
														)}
												</>
											) : (
												currencyFormat(product.price, false, shop.currency)
											)}
										</b>
									</div>
									<div className="buttons">
										{product.disabled != 1 && (
											<AddToCartButton product={product} />
										)}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			) : (
				<div className="products row kiosk-products">
					{products.map((product) => (
						<div key={product.id} className="col-6 col-sm-4 col-lg-4">
							<div
								className="product product-vertical"
								onClick={() => setProductSheet(product)}
							>
								<Badges product={product} />
								<div className="img">
									<img src={product.img} />
								</div>
								<div className="title">{product.name}</div>
								{product.stock && (
									<div className="stock small">Restanti: {product.stock}</div>
								)}
								<Groups product={product} />
								<Tags product={product} />
								<div
									className="small description"
									dangerouslySetInnerHTML={{ __html: product.description }}
								/>
								<div className="price-product">
									{product.discounted ? (
										<>
											<s>
												{currencyFormat(
													product.price,
													false,
													shop.currency
												)}
											</s>
											{" - " +
												currencyFormat(
													product.discount.price,
													false,
													shop.currency
												)}
										</>
									) : (
										currencyFormat(product.price, false, shop.currency)
									)}
								</div>
							</div>

							<div className="mt-2 add_button_container">
								{product.disabled != 1 && <AddToCartButton product={product} />}
							</div>
						</div>
					))}
				</div>
			)}
		</>
	);
}

export default ProductsList;
